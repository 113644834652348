import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Outlet } from 'react-router-dom';

//import components
import Footer from '../Components/Footer.jsx';

//import styles
import '../Styles/Layout.css';

const Layout = () => {

  return (
    <div className= 'layout'>

      <Outlet />

      <Footer />

    </div>
  );
};

export default Layout;