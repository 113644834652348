import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// Import styles
import '../Styles/Header.css';
import '../Styles/tokens.css';

// Import images
import logo from '../Images/icon_star.svg';
import soundIcon from '../Images/icon_sound.svg';
import soundIconNegative from '../Images/icon_sound_negative.svg';
import iconArrowRightSimple from '../Images/icon_arrow_right_simple.svg';
import iconClose from '../Images/icon_close.svg';

// Import components
import MusicPlayer from './MusicPlayer.jsx';

const Header = ({ menuToLayout }) => {
  const [menuState, setMenuState] = useState(false);
  const [visible, setVisible] = useState(true);
  const [menuMusicVisible, setMenuMusicVisible] = useState(true);
  const [currentSong, setCurrentSong] = useState({
    title: 'Aqua Regia',
    author: 'Sleep Token'
  });

  let lastScrollY = window.scrollY;

  const handleResize = () => {
    // No cambiar el estado del menú basado en el tamaño de la ventana
  };

  useEffect(() => {
    handleResize(); // Llamada inicial al efecto para manejar el estado del menú al cargar la página
    window.addEventListener('resize', handleResize);

    // Listener de scroll
    const handleScroll = () => {
      if (window.innerWidth > 1024) {
        if (window.scrollY > lastScrollY) {
          // Scroll hacia abajo
          setVisible(false);
        } else {
          // Scroll hacia arriba
          setVisible(true);
        }
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    // Limpieza del evento cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Controlador del menú
  const menuController = () => {
    const newMenuState = !menuState;
    setMenuState(newMenuState);
    menuToLayout(newMenuState); // Aquí pasamos el nuevo estado del menú a Layout
  };

  // Controlador del menú musical
  const menuMusicController = () => {
    setMenuMusicVisible(!menuMusicVisible);
  };

  // Estilos condicionales
  const mainHeaderStyle = {
    marginTop: visible ? '0' : '-100px',
    transition: 'margin-top 0.3s ease-in-out'
  };

  const menuToggle = menuState ? '' : 'grow';
  const menuElementsToggle = menuState ? '' : 'hidden';
  const iconToggle = menuState ? iconClose : iconArrowRightSimple;
  const menuTextToggle = menuState ? 'Cerrar' : 'Menu';
  const menuBGColor = menuState ? 'menuBG' : '';

  const toggleMenuMusic = menuMusicVisible ? 
    { opacity: 0, transition: 'ease all .5s' } : 
    { opacity: 1, transition: 'ease all .5s' };

  const musicButtonActive = menuMusicVisible
    ? { backgroundColor: 'transparent', transition: 'ease all .5s' }
    : {
        backgroundColor: 'var(--main-principal-color)',
        WebkitBoxShadow: '0px 0px 0px 8px var(--main-principal-color)',
        MozBoxShadow: '0px 0px 0px 8px var(--main-principal-color)',
        boxShadow: '0px 0px 0px 8px var(--main-principal-color)',
        zIndex: 9999,
        transition: 'ease all .5s'
      };

  const toggleIcon = menuMusicVisible ? soundIcon : soundIconNegative;
  const toggleMusicInfo = !menuMusicVisible ? { opacity: 0, transition: 'ease' } : { opacity: 1, transition: 'ease' };

  return (
    <div className={`main_header ${menuBGColor} line-appear`} style={mainHeaderStyle}>
      <div className='main_header_content'>
        <div className='logo_container'>
          <img 
            className='logo' 
            src={logo} 
            alt='Logo en forma de estrella de 6 puntas, de color negro'/>
          <span><Link smooth to='/'>JUANLU &nbsp;-- &nbsp;U X // U I</Link></span>
        </div>

        <div className='main_menu'>
          <div className='main_menu_trigger' onClick={menuController}>
            {menuTextToggle}
            <img src={iconToggle} alt='icono de flecha apuntando hacia abajo' />
          </div>
          <ul className={menuToggle}>
            <li className={menuElementsToggle} onClick={menuController}><Link to='/'>Inicio</Link></li>
            <li className={menuElementsToggle} onClick={menuController}><Link smooth to="/#Projects_Section">Proyectos</Link></li>
            <li className={menuElementsToggle} onClick={menuController}><Link smooth to="/#Perfil_Section">Perfil</Link></li>
          </ul>
        </div>
        
        <div className='menu_music'>
          <span style={toggleMusicInfo}>{currentSong.author} -- <i>{currentSong.title}</i></span>
          <div className='sound_button' style={musicButtonActive} onClick={menuMusicController}>
            <img src={toggleIcon} alt='botón para inciar la musica' />
          </div>
        </div>

        <div className="music_Player_Wrapper" style={{ ...toggleMenuMusic }}>
          <MusicPlayer setCurrentSong={setCurrentSong} />
        </div>
      </div>
    </div>
  );
};

export default Header;
