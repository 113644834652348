import React from 'react'

// styles imports
import '../Styles/Footer.css';
import '../Styles/tokens.css'

export const Footer = () => {
  return (
    <div className='Footer'>
      <ul>
        <li><a href='https://www.linkedin.com/in/juanluglez/' target='blank'>LinkedIn</a></li>
        <li><a href='https://github.com/JuanluGlez' target='blank'>gitHub</a> </li>
        <li>juanluglezportfolio © 2024</li>
      </ul>
    </div>
  )
}

export default Footer;