// styles imports
import './Styles/normalize.css';
import './Styles/CustomScrollbar.css';
import './Styles/App.css';

// components imports
import ScrollToTopOnPageChange from './Components/ScrollOnTop.jsx';
import SmoothScoll from './Components/SmoothScroll.jsx';
import CustomCursor from './Components/CustomCursor.jsx';
import AnimatedRoutes from './Components/AnimatedRoutes.jsx';


function App( ) {

  return (
    <>

    <ScrollToTopOnPageChange />
    <SmoothScoll />
    <CustomCursor />
    
    <AnimatedRoutes />
      
  </>
  );

}

export default App;
