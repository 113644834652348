import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import logoNegative from '../Images/logo_negative.svg';

const AnimatedPageTransition = () => {

  return (
    <>
      <motion.div
        initial={{ scaleY: 0, opacity: 0 }}
        animate={{ scaleY: 0, opacity: 0 }}
        exit={{ scaleY: 1, opacity: 1 }}
        transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          background: 'black',
          transformOrigin: 'bottom',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img 
          src={logoNegative} 
          alt="" 
          style={{
            width: '3rem',
          }}
        />
        
      </motion.div>
      
        <motion.div
          initial={{ scaleY: 1, }}
          animate={{ scaleY: 0 }}
          exit={{ scaleY: 0 }}
          transition={{ duration: 1, ease: [0.22, 1, 0.36, 1] }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            background: 'black',
            transformOrigin: 'top',
            zIndex: 9999
          }}
        >

        </motion.div>
        
        {/* <motion.div
        initial={{ scaleY: 1, }}
        animate={{ scaleY: 0 }}
        exit={{ scaleY: 0 }}
        transition={{ duration: 2, ease: [0.22, 1, 0.36, 1] }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          background: 'white',
          mixBlendMode: 'difference',
          transformOrigin: 'top',
          zIndex: 9999
        }}
      ></motion.div> */}
      
    </>
  )
}

export default AnimatedPageTransition