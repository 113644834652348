import React, { useState, useRef, useEffect } from 'react';

import '../Styles/MusicPlayer.css';

// import images
import prevIcon from '../Images/icon_music_player_prev.svg';
import nextIcon from '../Images/icon_music_player_next.svg';
import playIcon from '../Images/icon_music_player_play.svg';
import pauseIcon from '../Images/icon_music_player_pause.svg';
import coverBurnTheWitch from '../Images/cover_burnthewitch.jpeg';
import coverAquaRegia from '../Images/cover_aquaregia.jpeg';
import coverBadDesitions from '../Images/Cover_BadDesitions.png';
import coverChosen from '../Images/cover_Chosen.png';

// import sounds
import burnTheWitch from '../Sounds/ShawnJames–BurntheWitch.mp3';
import aquaRegia from '../Sounds/SleepToken-AquaRegia.mp3';
import badDesitions from '../Sounds/BADOMENS-baddecisions.mp3';
import chosenPessimist from '../Sounds/TheChosenPessimist.mp3';

// Lista de canciones con sus títulos, autores, carátulas y rutas de archivo
const songs = [

  {
    title: 'Aqua Regia',
    author: 'Sleep Token',
    cover: coverAquaRegia,
    src: aquaRegia
  },
  {
    title: 'Bad Desitions',
    author: 'Bad Omens',
    cover: coverBadDesitions,
    src: badDesitions
  },
  {
    title: 'The Chosen Pessimist',
    author: 'In Flames',
    cover: coverChosen,
    src: chosenPessimist
  },
  {
    title: 'Burn the Witch',
    author: 'Shawn James',
    cover: coverBurnTheWitch,
    src: burnTheWitch
  }
];

const MusicPlayer = ({ setCurrentSong }) => {

  // Estado para el índice de la canción actual
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  // Estado para indicar si la música se está reproduciendo o no
  const [isPlaying, setIsPlaying] = useState(false);
  // Referencia al elemento de audio para controlar la reproducción
  const audioRef = useRef(null);

  useEffect(() => {
    // Asegúrate de que el elemento de audio está montado y la fuente está configurada
    if (audioRef.current) {
      audioRef.current.src = songs[currentSongIndex].src;
      if (isPlaying) {
        audioRef.current.play();
      }
      setCurrentSong({
        title: songs[currentSongIndex].title,
        author: songs[currentSongIndex].author
      });
    }
  }, [currentSongIndex, isPlaying, setCurrentSong]);

  // Maneja el botón de reproducir/pausar
  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Maneja el botón de siguiente canción
  const handleNext = () => {
    const nextIndex = (currentSongIndex + 1) % songs.length;
    setCurrentSongIndex(nextIndex);
    setIsPlaying(true);
  };

  // Maneja el botón de canción anterior
  const handlePrev = () => {
    const prevIndex = (currentSongIndex - 1 + songs.length) % songs.length;
    setCurrentSongIndex(prevIndex);
    setIsPlaying(true);
  };

  return (
    <div className='music_player'>
      {/* Info de la canción */}
      
      <div className='info_container'>
        {/* Muestra la carátula de la canción actual */}
        <img src={songs[currentSongIndex].cover} alt={songs[currentSongIndex].title} className='cover' />
        
        {/* Muestra el título y el autor de la canción actual */}
        <div className='info'>
          <h2 className='title'>{songs[currentSongIndex].title}</h2>
          <p className='author'>{songs[currentSongIndex].author}</p>
        </div>
      </div>

      {/* Controladores */}
      <div className='controls_container'>
        {/* Botón para la canción anterior */}
       
        <button className='button' onClick={handlePrev}>
          <img src={prevIcon} alt="Previous" className='icon' />
        </button>
        {/* Botón de reproducir/pausar que cambia de icono según el estado de reproducción */}
        
        <button className='button' onClick={handlePlayPause}>
          <img src={isPlaying ? pauseIcon : playIcon} alt={isPlaying ? 'Pause' : 'Play'} className='icon' />
        </button>
        {/* Botón para la siguiente canción */}
        
        <button className='button' onClick={handleNext}>
          <img src={nextIcon} alt="Next" className='icon'/>
        </button>

      </div>
      {/* Elemento de audio oculto para la reproducción */}
      <audio ref={audioRef} />
    </div>
  );
};

export default MusicPlayer;
