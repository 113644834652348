import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

// Import routes
import Layout from '../Pages/Layout.jsx';
import Home from '../Pages/Home.jsx';
import ProjectDetails from '../Pages/ProjectDetails.jsx';
import NoPage from '../Pages/NoPage.jsx';

// Data imports
import projectsData from '../Data/projects.json';

// Components import 
import Header from './Header.jsx';

// Styles imports
import '../Styles/Layout.css';

function AnimatedRoutes() {
  const location = useLocation();

  // Aquí determinamos el ancho de pantalla
  const handleResize = () => {
    setMenuData(menuData);
  };

  useEffect(() => {
    handleResize(); // Llamada inicial al efecto para manejar el estado del menú al cargar la página
    window.addEventListener('resize', handleResize);

    // Limpieza del evento cuando el componente se desmonta
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Comunicamos layout con Header para recibir el estado del menú
  const [menuData, setMenuData] = useState(false);

  const menuModifier = menuData ? 'menuModify' : 'menuInitial';

  console.log(menuData);

  // Aquí manejamos el estado del componente según el scroll
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const mainHeaderStyle = {
    marginTop: visible ? '0' : '-100px',
    transition: 'margin-top 0.3s ease-in-out'
  };

  return (
    <div className={menuModifier}>
      <AnimatePresence mode='wait'>
        <Routes basename="/" location={location} key={location.pathname}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            {projectsData.map((project) => (
              <Route
                key={project.ID}
                path={`/proyecto/${project.ID}`} 
                element={<ProjectDetails project={project} />} 
              />
            ))}
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </AnimatePresence>

      <div className='page_header'>
        <Header menuToLayout={setMenuData} />
        <div className='background_blended' style={mainHeaderStyle}></div>
      </div>
    </div>
  );
}

export default AnimatedRoutes;
